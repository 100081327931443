<template>
  <article class="message is-success is-small">
    <div class="message-body">アカウント情報の更新が完了しました。</div>
  </article>
  <div class="content">
    <ExplainLink />
  </div>
</template>

<script>
import ExplainLink from "../components/elements/ExplainLink.vue"

export default {
  name: "UserEditComplete",
  components: {
    ExplainLink,
  },
}
</script>
